.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  background-image: url('./assets/images/fondologin 3.png');
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  background-color: #282c34;
  background-image: url('./assets/images/fondologin 3.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: '100%';
  height: '100%';
  opacity: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Estilos CSS para centrar los títulos de las pestañas en el componente Tabs */
.tabs-container .ant-tabs-nav {
  text-align: center;
}

.tabs-container .ant-tabs-tab {
  margin: 0;
}

.tabs-container .ant-tabs-tab-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* Opcional: Estilos para resaltar la pestaña activa */
.tabs-container .ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: bold;
  color: #1890ff; /* Puedes ajustar el color según tu preferencia */
}

.contenedor {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Divide el contenedor en dos columnas iguales */
  height: 100vh; /* Establece la altura del contenedor al 100% del viewport height */

  @media (max-width: 767px) {
    width: auto;
    grid-template-columns: 1fr; /* Cambia a una sola columna en dispositivos móviles */
  }
}

.contenedortipoA {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Divide el contenedor en dos columnas iguales */
  /* height: ; /* Establece la altura del contenedor al 100% del viewport height */
  @media (max-width: 767px) {
    grid-template-columns: 1fr; /* Cambia a una sola columna en dispositivos móviles */
  }
}
.map {
  display: grid;
  height: 100vh; /* Establece la altura del contenedor al 100% del viewport height */
}

.columna {
  border: 1px solid rgb(232, 232, 232); /* Borde para visualizar las columnas */
  padding: 10px;
  text-align: center;

  @media (max-width: 767px) {
    width: 100%; /* Ocupa el ancho completo en dispositivos móviles */
    grid-column: unset; /* Elimina la propiedad grid-column para evitar conflictos */
  }
}
.columnaP {
  border: 1px solid rgb(232, 232, 232); /* Borde para visualizar las columnas */
  padding: 10px;
  text-align: center;

  /* Estilos para tamaños de pantalla mayores a 600 píxeles de ancho */
}

.columnaPdf {
  padding: 10px;
  text-align: left;

  @media (max-width: 767px) {
    grid-column: span 2; /* Ocupa el ancho completo en dispositivos móviles */
  }
}

.textAjust {
  text-align: justify;
}
.componentWeb {
  text-align: justify;
}
@media (min-width: 601px) {
  .textAjust {
    display: block;
  }
  .columnaP {
    display: block; /* o cualquier otra propiedad que desees */
  }
  .componentWeb {
    display: none;
  }
}

/* Estilos para tamaños de pantalla de 600 píxeles de ancho o menos (celulares) */
@media (max-width: 600px) {
  .textAjust {
    display: none;
  }
  .columnaP {
    display: none;
  }
  .componentWeb {
    display: block;
  }
}

platform-name .texto-justificado {
  text-align: justify;
}

.iframe-container {
  margin-bottom: 2px; /* Espacio entre el primer iframe y los siguientes */
}

.iframe-row {
  display: flex;
  justify-content: space-between; /* Distribuir los iframes de manera uniforme */
}

.pagina-A4 {
  width: 720px; /* 210mm * 3.78 */
  /*height: 1123px; /* 297mm * 3.78 */
  margin: 20px auto; /* Alinea el div en el centro de la página */
}

.scrollable-div {
  height: 80vh; /* 80% de la altura de la vista */
  overflow-y: auto; /* Agrega un scroll si el contenido excede la altura especificada */
}
.parrafo-arial-10 {
  width: 600px;
  margin-right: 20px;
  font-family: 'Arial', sans-serif;
  font-size: 10px;
  text-align: justify;
}

dl {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Mostrar en dos columnas */
  gap: 10px; /* Espaciado entre elementos */
}

dt {
  font-weight: bold;
}
