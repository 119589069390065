/* TablaSubActividades.css */

.fila-amarilla {
  background-color: #e1bf0d;
}

.fila-Azul {
  background-color: #7d7dbb;
}

.fila-verde {
  background-color: #7ac142;
}
