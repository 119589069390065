@import url('https://fonts.googleapis.com/css2?family=Poppins');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  /* font-size: 14px; */
  font-family: 'Poppins', sans-serif !important;
}
.ant-button > * {
  background-color: #b1c54f !important;
}
.ant-menu-item > * {
  color: #dee0d7 !important;
}

.ant-menu-item-selected {
  background-color: #b1c54f !important;
}

.ant-menu-item-selected > * {
  /* color: white !important; */
  color: #b1c54f !important;
}
.ant-pagination-options {
  display: none !important;
}
.ant-menu-item-content {
  white-space: normal;
}
.multi-line-label {
  white-space: normal;
}

div.ql-container.ql-bubble.ql-disabled > div.ql-editor {
  padding: 0;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ant-descriptions .ant-descriptions-row > td {
  padding-bottom: 1px;
}
